import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

// Global vuex
import MemberModule from './member'
import DashboardMobule from './dashboard'
Vue.use(Vuex)

/**
 * Main Vuex Store
 */
const store = new Vuex.Store({
  modules: {
    member: MemberModule,
    dashboard: DashboardMobule
  },
  plugins: [
    createPersistedState({
      key: 'com.ufalove.agent',
      paths: ['member.token']
    })
  ]
})

export default store