<template>
  <div>
  <v-container class="py-5">
    <v-row>
      <v-col>
        <v-card class="fill-height">
          <v-card-text class="fill-height d-flex flex-column justify-space-between flex-wrap">
            <div class="caption">จำนวนเงินถอนทั้งหมดจาก BANKING</div>
            <div class="my-3 red--text display-1">{{ dashboard && dashboard.total_real_money ? number_format(dashboard.total_real_money.total_withdraw_from_internet_yesterday) : '0' }}</div>
            <div class="caption text-right">ของวันที่ {{ dashboard && dashboard.total_real_money ? dashboard.total_real_money.yesterday : '0000-00-00' }}</div> 
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card class="fill-height">
          <v-card-text class="fill-height d-flex flex-column justify-space-between flex-wrap">
            <div class="caption">จำนวนเงินฝากเข้า UFA (เฉพาะรายการที่มีการโอนเข้าบัญชี)</div>
            <div class="my-3 cyan--text lighten-4 display-1"> {{ dashboard && dashboard.total_real_money ? number_format(dashboard.total_real_money.total_deposit_yesterday) : '0' }}</div>
            <div class="caption text-right align-end">ของวันที่ {{ dashboard && dashboard.total_real_money ? dashboard.total_real_money.yesterday : '' }}</div> 
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card class="fill-height">
          <v-card-text class="fill-height d-flex flex-column justify-space-between flex-wrap">
            <div class="caption">สรุปยอดสุทธิ์เฉพาะเงินเข้าและออกจริง</div>
            <div class="my-3 purple--text lighten-4 display-1">{{ dashboard && dashboard.total_real_money ? number_format(dashboard.total_real_money.total_deposit_yesterday - dashboard.total_real_money.total_withdraw_from_internet_yesterday) : '0'}}</div>
            <div class="caption text-right">ของวันที่ {{ dashboard && dashboard.total_real_money ? dashboard.total_real_money.yesterday : '0000-00-00' }}</div> 
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <v-form>
              <v-row class="my-2">
                <v-col cols="12" md="5" class="d-flex align-center">
                  <span class="mx-1">จาก</span>
                  <v-menu
                    ref="menuFrom"
                    v-model="menuFrom"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="from"
                        label="Date"
                        persistent-hint
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="from"
                      no-title
                      @input="menuFrom = false"
                    ></v-date-picker>
                  </v-menu>
                  <v-menu
                    ref="menuFromTime"
                    v-model="menuFromTime"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="from_time"
                        label="Time"
                        persistent-hint
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="menuFromTime"
                      v-model="from_time"
                      full-width
                      @click:minute="$refs.menu.save(from_time)"
                    ></v-time-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="5" class="d-flex align-center">
                  <span class="mx-1">ถึง</span>
                  <v-menu
                    ref="menuFrom"
                    v-model="menuTo"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="to"
                        label="Date"
                        persistent-hint
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="to"
                      no-title
                      @input="menuTo = false"
                    ></v-date-picker>
                  </v-menu>
                  <v-menu
                    ref="menuFromTime"
                    v-model="menuToTime"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="to_time"
                        label="Time"
                        persistent-hint
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="menuToTime"
                      v-model="to_time"
                      full-width
                      @click:minute="$refs.menu.save(to_time)"
                    ></v-time-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="2" class="d-flex align-center justify-end">
                  <v-btn color="primary" @click="load" :loading="loading">ค้นหา</v-btn>
                </v-col>
              </v-row>
            </v-form>
            <v-divider/>
            <v-row>
              <v-col>
                <h1 class="cyan--text lighten-4 font-weight-thin my-5" >Income</h1>
                <v-row class="mt-1">
                  <v-col>ยอดแจ้งฝากทั้งหมด</v-col>
                  <v-col>{{ dashboard && dashboard.accounting ? number_format(dashboard.accounting.deposit_total) : '0' }}</v-col>
                </v-row>
                <v-divider/>
                <v-row class="mt-1">
                  <v-col>ยอดเติมเข้า UFA</v-col>
                  <v-col>{{ dashboard.deposit_sum }}</v-col>
                </v-row>
                <v-divider/>
                <v-row class="mt-1">
                  <v-col>ยอดเข้าบัญชี</v-col>
                  <v-col>{{ dashboard && dashboard.accounting ? number_format(dashboard.accounting.deposit_sms) : '0'}}</v-col>
                </v-row>
              </v-col>
               
              <v-col>
                <h1 class="purple--text lighten-4 font-weight-thin my-5">Outcome</h1>
                <v-row class="mt-1">
                  <v-col>ยอดแจ้งถอนทั้งหมด</v-col>
                  <v-col>{{ dashboard && dashboard.accounting ? number_format(dashboard.accounting.withdraw_total) : '0' }}</v-col>
                </v-row>
                <v-divider/>
                <v-row class="mt-1">
                  <v-col>ยอดตัดจาก UFA</v-col>
                  <v-col>{{ dashboard.withdrawal_sum }}</v-col>
                </v-row>
                <v-divider/>
                <v-row class="mt-1">
                  <v-col>ยอดถอนบัญชี</v-col>
                  <v-col>{{ dashboard && dashboard.accounting ? number_format(dashboard.accounting.withdraw_sms) : '0' }}</v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <v-row>
              <v-col>
                <h1 class="cyan--text lighten-4 font-weight-thin">ยอดฝาก</h1>
                <span> 5 รายการล่าสุด </span>
              </v-col>
              <v-col>
                <h1 class="font-weight-light"> <span>฿</span> {{ dashboard.deposit_sum }}</h1>
              </v-col>
            </v-row>
            <v-simple-table>
              <tbody>
                <tr
                  v-for="item in dashboard.deposit_lists"
                  :key="item.id"
                >
                  <td>{{ item.created_at }}</td>
                  <td align="right">{{ status(item) }}</td>
                  <td width="100" style="border-left: 1px dotted;">{{ number_format(item.amount) }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card>
          <v-card-text>
            <v-row>
              <v-col>
                <h1 class="red--text lighten-4 font-weight-thin">ยอดถอน</h1>
                <span> 5 รายการล่าสุด </span>
              </v-col>
              <v-col>
                <h1 class="font-weight-light">22,552,525.73</h1>
              </v-col>
            </v-row>
            <v-simple-table>
              <tbody>
                <tr
                  v-for="item in dashboard.withdrawal_lists"
                  :key="item.id"
                >
                  <td>{{ item.updated_at }}</td>
                  <td align="right">{{ status(item) }}</td>
                  <td width="100" style="border-left: 1px dotted;">{{ number_format(item.amount) }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6" md="4" v-for="item in conclusion" :key="item.title">
        <v-card class="fill-height" :style="{ borderBottom: '3px solid ' + item.color }">
          <v-card-text class="fill-height d-flex flex-column justify-space-between flex-wrap">
            <div class="caption">{{ item.title }}</div>
            <h1 class="my-5 font-weight-thin">{{ item.count }} ครั้ง</h1>
            <div class="d-flex flex-column flex-md-row justify-space-between caption">
              <span>คิดเป็น {{ item.percent }}%</span>
              <span :class="(item.color !== 'white' ? item.color : 'black' ) + '--text'">ก่อนหน้านี้ {{ item.count_before }} ครั้ง</span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card style="height: 450px">
          <v-card-text><canvas ref="canvas" height="300" style="max-height: 400px"></canvas></v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left" v-for="h in headers" :key="'header'+h.text">
                      {{ h.text }}
                    </th>
                  </tr>
                </thead>
                <tbody v-if="dashboard && dashboard.charts">
                  <tr
                    v-for="i in dashboard.charts.dates.length-1"
                    :key="'row'+i"
                  >
                    <td>{{ dashboard.charts.dates[i] }}</td>
                    <td>{{ dashboard.charts.new_registers_first_deposit[i] }}</td>
                    <td>{{ dashboard.charts.new_registers_first_deposit[i] }}</td>
                    <td>{{ dashboard.charts.new_registers_normal[i] }}</td>
                    <td>{{ dashboard.charts.new_registers_agent[i] }}</td>
                    <td>{{ dashboard.charts.player[i] }}</td>
                  </tr>
                </tbody>
                <tfoot v-if="dashboard && dashboard.charts">
                  <tr>
                    <td>รวม</td>
                    <td>{{ number_format(dashboard.charts.new_registers.reduce((a, b) => a + b, 0)) }}</td>
                    <td>{{ number_format(dashboard.charts.new_registers_first_deposit.reduce((a, b) => a + b, 0)) }}</td>
                    <td>{{ number_format(dashboard.charts.new_registers_normal.reduce((a, b) => a + b, 0)) }}</td>
                    <td>{{ number_format(dashboard.charts.new_registers_agent.reduce((a, b) => a + b, 0)) }}</td>
                    <td>{{ number_format(dashboard.charts.player.reduce((a, b) => a + b, 0)) }}</td>
                  </tr>
                </tfoot>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container></div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Chart from 'chart.js/auto'
export default {
  data() {
    return {
      loading: false,
      menuFrom: false,
      menuFromTime: false,
      menuTo: false,
      menuToTime: false,

      from: '',
      from_time: '',
      to: '',
      to_time: '',

      headers: [
        { text: 'วันที่', value: 'created_at', sortable: false},
        { text: 'สมาชิกใหม่', value: 'new_member', sortable: false},
        { text: 'สมาชิกใหม่และฝากเงิน', value: 'new_member_deposit', sortable: false},
        { text: 'สมัครแบบทั่วไป', value: 'member_nomal', sortable: false},
        { text: 'สมัครผ่านตัวแทน', value: 'member_agent', sortable: false},
        { text: 'จำนวนคนเล่น', value: 'player', sortable: false}
      ],

      chart: null
    }
  },
  async mounted() {
    const now = new Date()
    const date = `${now.getFullYear()}-${('0'+now.getMonth()).substr(-2)}-${('0'+now.getDate()).substr(-2)}` 
    
    this.from = date
    this.from_time = '00:00'
    this.to = date
    this.to_time = `${('0'+now.getHours()).substr(-2)}:${('0'+now.getMinutes()).substr(-2)}`
    const data = {
        from: this.from,
        form_time: this.from_time,
        to: this.to,
        to_time: this.to_time
      }
    await this.loadDashboard(data).then(() => {
      this.runChart()
    })
  },
  computed: {
    ...mapGetters('dashboard', ['dashboard']),
    conclusion () {
      return [
        { 
          title: 'รายการฝาก (อนุมัติ)',
          color: 'cyan',
          count: this.dashboard.deposit_count,
          percent: this.dashboard.deposit_count_percent,
          count_before: this.dashboard.deposit_count_before
        },
        {
          title: 'รายการถอน (ทั้งหมด)',
          color: 'yellow',
          count: this.dashboard.withdrawal_count_total,
          percent: this.dashboard.withdrawal_count_total_percent,
          count_before: this.dashboard.withdrawal_count_total_before
        },
        {
          title: 'รายการถอน (โอนเงิน)',
          color: 'purple',
          count: this.dashboard.withdrawal_count_complete,
          percent: this.dashboard.withdrawal_count_complete_percent,
          count_before: this.dashboard.withdrawal_count_complete_before
        },
        {
          title: 'กิจกรรมทายผลบอล',
          color: 'white',
          count: this.dashboard && this.dashboard.activity ? this.number_format(this.dashboard.activity.predict) : '0',
          percent: this.dashboard && this.dashboard.activity ? (this.dashboard.activity.predict != 0 ? this.dashboard.activity.predict_deposit * 100 / this.dashboard.activity.predict : 0) : '0',
          count_before: this.dashboard && this.dashboard.activity ? this.dashboard.activity.predict_deposit : '0' 
        },
        {
          title: 'กิจกรรมทายหวย',
          color: 'white',
          count: this.dashboard && this.dashboard.activity ? this.number_format(this.dashboard.activity.lotto) : '0',
          percent: this.dashboard && this.dashboard.activity ? (this.dashboard.activity.lotto != 0 ? this.dashboard.activity.lotto_deposit * 100 / this.dashboard.activity.lotto : 0) : '0',
          count_before: this.dashboard && this.dashboard.activity ? this.dashboard.activity.lotto_deposit : '0'
        },
        {
          title: 'ฝากเร็ว',
          color: 'red',
          count: this.dashboard && this.dashboard.cdm ? this.number_format(this.dashboard.cdm.success) : '0',
          percent: this.dashboard && this.dashboard.cdm ? (this.dashboard.cdm.total != 0 ? this.dashboard.cdm.success * 100 / this.dashboard.cdm.total : 0) : '0',
          count_before: this.dashboard && this.dashboard.cdm ? this.dashboard.cdm.total : '0'
        }
      ]
    }
  },
  methods: {
    ...mapActions('dashboard', ['loadDashboard']),
    load () {
      this.loading = true
      const data = {
        from: this.from,
        form_time: this.from_time,
        to: this.to,
        to_time: this.to_time
      }

      this.loadDashboard(data)
        .then(() => {
          if (this.chart === null) this.runChart()
        })
        .catch((error) =>{
          console.log(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    number_format (number) {
      try{
        if (!number) return number
        if (String(number).indexOf(',') >= 0) return number
        
        return Number(parseFloat(String(number)).toFixed(2)).toLocaleString()
      }
      catch(error) {
        console.log(number, typeof number)
      }
    },
    status (item) {
      if (item.active === 0)
        return 'ยกเลิกรายการ'
      else if (item.complete === 1)
        return 'ยืนยันแล้ว'
      else if (item.confirm_user_id)
        return 'กำลังตรวจสอบ'
      else
        return 'รอตรวจสอบ'
    },
    runChart () {
      this.chart = new Chart(
        this.$refs.canvas,
        {
          type: 'line',
          data: {
            labels: this.dashboard.charts.dates,
            datasets: [
              {
                label: 'สมาชิกใหม่',
                backgroundColor: 'rgb(74, 35, 90)',
                borderColor: 'rgb(74, 35, 90)',
                data: this.dashboard.charts.new_registers,
              },
              {
                label: 'สมาชิกใหม่ และ ฝากเงิน',
                backgroundColor: 'rgb(100, 149, 237)',
                borderColor: 'rgb(100, 149, 237)',
                data: this.dashboard.charts.new_registers_first_deposit,
              },
              {
                label: 'สมัครแบบทั่วไป',
                backgroundColor: 'rgb( 20, 90, 50)',
                borderColor: 'rgb( 20, 90, 50)',
                data: this.dashboard.charts.new_registers_normal,
              },
              {
                label: 'สมัครผ่านตัวแทน',
                backgroundColor: 'rgb( 230, 126, 34 )',
                borderColor: 'rgb( 230, 126, 34  )',
                data: this.dashboard.charts.new_registers_agent,
              },
              {
                label: 'จำนวนคนเล่น',
                backgroundColor: 'rgb( 249, 231, 159)',
                borderColor: 'rgb( 249, 231, 159 )',
                data: this.dashboard.charts.player
              },
            ]
          },
          options: {}
        }
      )
    }
  }
}
</script>

<style>

</style>