<template>
  <v-container>
    <v-row class="text-center">
      <v-col
        cols="12"
        md="10"
        lg="8"
        xl="6"
        class="mx-auto"
      >
        <v-data-table
          :headers="headers"
          :items="members"
          :options.sync="options"
          :server-items-length="totalMembers"
          :loading="loading"
          class="elevation-1"
        >
          <template #top>
            <v-text-field
              v-model="search"
              label="ค้นหา"
              class="mx-4"
            ></v-text-field>
          </template>

          <template #[`item.mine`]="{ item }">
            <!-- <v-chip
              :color="item.mine === 1 ? 'green' : 'grey'"
              dark
            > -->
            <v-icon :color="item.mine === 1 ? 'green' : 'grey'">{{ item.mine === 1 ? 'mdi-account-check' : 'mdi-cancel' }}</v-icon>
            <!-- </v-chip> -->
          </template>
        </v-data-table>
      </v-col>      
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Member',

  data: () => ({
    loading: true,
    search: '',
    headers: [
      {
        text: 'ชื่อ',
        align: 'start',
        sortable: false,
        value: 'bank_user',
      },
      {
        text: 'Ufa user',
        align: 'start',
        sortable: false,
        value: 'ufa_user',
      },
      {
        text: 'ลูกค้า',
        align: 'center',
        sortable: false,
        value: 'mine',
      },
    ],
    options: {},
    queryTimeout: null
  }),

  computed: {
    ...mapGetters({
			members: 'member/members'
		}),

    totalMembers () {
      return this.members.length
    }
  },

  watch: {
    search () {
      clearTimeout(this.queryTimeout)
      this.queryTimeout = setTimeout(() => {
        this.query()
      }, 500)
    }
  },

  mounted () {
    this.loading = false
  },

  methods: {
    ...mapActions({
      memberInquiry: 'member/memberInquiry'
    }),

    query () {
      this.loading = true
      this.memberInquiry({ text: this.search }).catch(err => {
        console.log(err)
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>
