import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'

// Routes
//import PagesRoutes from './pages.routes'

import Dashboard from '../components/Dashboard'
import Login from '../components/Login'
import Member from '../components/Member'
Vue.use(Router)



export const routes = [
  {
    path: '/',
    redirect: 'dashboard'
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      auth: true
    }
  },
  {
    path: '/member',
    name: 'member',
    component: Member,
    meta: {
      auth: true
    }
  }
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL || '/',
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.name === 'login') return next()
  if (store.getters['member/me'] === null) await store.dispatch('member/authValidation', {})
  if (to.meta.auth && store.getters['member/me'] === null)  return router.replace({ name: 'login' })
  
  return next()
})

export default router