import Vue from 'vue'
//import Vuex from 'vuex'
import App from './App.vue'
import vuetify from './plugins/vuetify'

import store from './store'
import router from './router'
//import member from './store/member'

Vue.config.productionTip = false

// Vue.use(Vuex)
// const store = new Vuex.Store({
//   modules: {
//     member
//   },
//   plugins: [
//     createPersistedState({
//       key: 'com.ufalove.agent',
//       paths: ['member.token']
//     })
//   ]
// })

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
