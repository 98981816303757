const axios = require('axios')
const api = 'https://member.ufalove.com/api' //'http://127.0.0.1:8000/api' //

export default {
	namespaced: true,

	state: {
		token: null,
		me: null,
		members: []
	},

	getters: {
		token: state => state.token,
		me: state => state.me,
		members: state => state.members
	},

	mutations: {
		updateMe (state, data) {
			if (data) {
				state.me = data.me
				state.token = data.token
			} else {
				state.me = null
				state.token = null
			}
		},

		updateMembers (state, data) {
			state.members = data.members
		},
	},

	actions: {
		authValidation ({ commit, state }) {
			return new Promise((resolve, reject) => {
				axios
					.post(api + '/agent-auth-validation', { token: state.token })
					.then(res => {
						if (res.data.error !== true) {
							commit('updateMe', {
								me: res.data.me || null,
								token: res.data.token || null
							})
							resolve(res.data)
						} else {
							reject(res.data)
						}
					})
					.catch(err => {
						console.log(err)
						reject(err)
					})
			})
		},

		userAuth ({ commit }, payload) {
			return new Promise((resolve, reject) => {
				axios
					.post(api + '/agent-auth', payload)
					.then(res => {
						if (res.data.error !== true) {
							commit('updateMe', { 
								me: res.data.me || null, 
								token: res.data.token || null 
							})
							resolve(res.data)
						} else {
							reject(res.data)
						}
					})
					.catch(err => {
						console.log(err)
						reject(err)
					})
			})
		},

		userSignout ({ commit, state }) {
			return new Promise((resolve, reject) => {
				axios
					.post(api + '/agent-signout', { token: state.token })
					.then(res => {
						if (res.data.error !== true) {
							commit('updateMe', null)
							resolve()
						} else {
							reject(res.data)
						}
					})
					.catch(err => {
						console.log(err)
						reject(err)
					})
			})
		},

		memberInquiry ({ commit, state }, payload) {
			return new Promise((resolve, reject) => {
				axios
					.post(api + '/member-inquiry', { ...payload, token: state.token })
					.then(res => {
						if (res.data.error !== true) {
							commit('updateMembers', { members: res.data.members || [] })
							resolve()
						} else {
							if (res.data.auth === false) {
								commit('updateMe', null)
							}
							reject(res.data)
						}
					})
					.catch(err => {
						console.log(err)
						reject(err)
					})
			})
		}

	}
}