<template>
  <div class="py-5">
    <v-card class="mx-auto pa-5" width="500">
      <v-progress-circular
        v-if="loading"
        :size="150"
        color="primary"
        indeterminate
      />

      <template v-else>
        <v-card-text class="pt-0 px-0">
          <v-form ref="signInForm" v-model="valid" lazy-validation>
            <v-text-field
              v-model="username"
              :rules="[(v) => !!v || 'จำเป็นต้องระบุชื่อผู้ใช้']"
              label="ชื่อผู้ใช้"
              required
            />
            <v-text-field
              v-model="password"
              :rules="[(v) => !!v || 'จำเป็นต้องระบุรหัสผ่าน']"
              label="รหัสผ่าน"
              type="password"
              required
            />
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="orange" @click="signin"> เข้าสู่ระบบ </v-btn>
        </v-card-actions>
      </template>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data: () => ({
    loading: true,
    snackbar: false,
    snackbar_text: "",
    valid: false,
    username: '',
    password: '',
  }),

  computed: {
    ...mapGetters({
      me: "member/me",
    }),
  },

  mounted() {
    this.authValidation()
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.loading = false;
      });
  },

  methods: {
    ...mapActions({
      authValidation: "member/authValidation",
      userAuth: "member/userAuth",
      userSignout: "member/userSignout",
    }),

    signin() {
      if (this.$refs.signInForm.validate()) {
        this.snackbar = false;

        this.userAuth({
          user: this.username,
          password: this.password,
        })
          .then(() => {
            this.$router.replace({ name: "dashboard" });
          })
          .catch((err) => {
            console.log('error', err)
            this.snackbar_text = err.message;
            this.snackbar = true;
          });
      }
    },
  },
};
</script>

<style>
</style>