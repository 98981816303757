import Vue from 'vue'

const axios = require('axios')
const api = 'https://member.ufalove.com/api' //'http://127.0.0.1:8000/api' //

export default {
	namespaced: true,

	state: {
    dashboard: {}
	},

	getters: {
		dashboard: (state) => state.dashboard,
	},

	mutations: {
    SET_DASHBOARD: (state, data) => Vue.set(state, 'dashboard', data)
	},

	actions: {
		loadDashboard ({ commit, rootGetters }, payload) {
      return new Promise((resolve, reject) => {
				axios
          .post(api + '/dashboard', { ...payload, token: rootGetters['member/token'] })
					.then(res => {
						if (res.data.error !== true) {
              commit('SET_DASHBOARD', res.data.data)
							resolve(res.data.data)
						} else {
							reject(res.data.error)
						}
					})
					.catch(err => {
						console.log(err)
						reject(err)
					})
			})
		}
	}
}