<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center">
        <v-img
          alt="UFA Love Logo"
          class="shrink mr-2"
          contain
          :src="require('./assets/ball.png')"
          transition="scale-transition"
          width="50"
        />
      </div>
      <v-menu v-if="me">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-menu</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item to="/dashboard">
              <v-list-item-title>Dashboard</v-list-item-title>
            </v-list-item>
            <v-list-item to="/member">
              <v-list-item-title>Member</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      <v-spacer />

      <v-toolbar-title class="mr-5">
        {{ me ? me['user'] : '' }}
      </v-toolbar-title>

      <v-btn
        v-if="me"
        icon
        @click="signout"
      >
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      
      <router-view />
    
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'App',
  computed: {
    ...mapGetters('member', ['me'])
  },
  methods: {
    ...mapActions('member', ['userSignout']),
    signout () {
      this.loading = true
      this.userSignout()
      .catch(err => {
        this.snackbar_text = err.message
        this.snackbar = true
      }).finally(() => {
        setTimeout(() => {
          this.loading = false
        }, 1000)
        this.$router.replace({name: 'login'})
      })
    }
  }
};
</script>
